/* .rbt-close {
  font-size: 1rem;
}
.rbt-close-sm {
  font-size: 0.75rem;
}
.rbt-close-content {
  display: none;
}

.rbt-aux {
  width: 2.5rem;
}
.rbt-aux-lg {
  width: 3rem;
}
.rbt-aux .rbt-close {
  margin-top: 0;
}

.rbt .form-floating {
  flex: 1;
}

.form-floating > .rbt-input-multi:not(:placeholder-shown) ~ label {
  opacity: inherit;
  transform: inherit;
}
.form-floating > .rbt-input-multi .rbt-input-main::placeholder {
  color: transparent;
}
.form-floating > .rbt-input-multi.focus ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
} */

.rbt-token-removeable > button {
  border-width: 0;
  background: transparent;
}
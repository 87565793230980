body {
  margin: 0;
  font-family: -apple-system
  
  .BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.cda-bold {
  font-weight: 600
}

.cda-underline {
  text-decoration: underline;
}

.cda-emphasis {
  font-weight: 600
}

.cda-italics {
  font-style: italic;
}

.cda-xplred {
  color: red;
}

.cda-xplblue {
  color: blue;
}

.cda-xplgreen {
  color: green;
}

.cda-xpllime {
  color: lime;
}

.cda-xplgray {
  color: gray;
}

.cda-xplviolet {
  color: violet;
}

.cda-xplpurple {
  color: purple;
}

.cda-xplorange {
  color: orange;
}

.cda-xplolive {
  color: olive;
}

.cda-xplnavy {
  color: navy;
}

.cda-xplsilver {
  color: silver;
}

.cda-xplbig {
  font-size: 1.2em
}

.cda-xplsmall {
  font-size: 0.8em
}

.cda-xplxsmall {
  font-size: 0.6em
}